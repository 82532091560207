import React from "react";
import "./App.css";
import FraudCard from "./components/FraudCard";
import { ChakraProvider, Link, Text } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <FraudCard
        fraudsterName="Mario Grasbock"
        fraudsterImage={require("../src/assets/mario2.png")}
        estimatedMoneyLost="$550K - 1M"
        fraudsterDescription={
          <Text>
            Mario Grasbock is a con-artist, a fraudster who committed many
            defrauding and money laundering crimes. He is a skilled manipulator
            and displays signs of
            <Link
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4321752/#:~:text=Psychopathy%20is%20a%20neuropsychiatric%20disorder,antisocial%20deviance%20and%20criminal%20behavior."
              isExternal
              color="blue.600"
            >
              {" "}
              psychopathy
            </Link>{" "}
            or
            <Link
              href="https://www.mayoclinic.org/diseases-conditions/antisocial-personality-disorder/symptoms-causes/syc-20353928#:~:text=Antisocial%20personality%20disorder%2C%20sometimes%20called,rights%20and%20feelings%20of%20others."
              isExternal
              color="blue.600"
            >
              {" "}
              sociopathy
            </Link>{" "}
            such as lack of remorse, cunning manipulation, lack of empathy (yet
            having an uncanny ability to mimic it), chronic lying and
            impulsivity. <br /> <br />
            Mario has defrauded multiple people claiming that he is an investor
            trying to help them, with a pure intention of building a wellness
            center to heal people and animals. All of these turned out to be
            lies. What we know as of now is that Mario builds trust to exploit
            his victims. Keep in mind, Mario is really skilled at lying and
            manipulation and can sound truthful but his actions don't match his
            words.
          </Text>
        }
        partners={[
          {
            name: "Matthew Lobene",
          },
          {
            name: "Joshua Staurt Koch",
            socialHandleLink:
              "https://www.linkedin.com/in/josh-s-koch-0529379b",
          },

          {
            name: "Rafael Lopes Brasileiro Martim",
          },
        ]}
        // spouses={[
        //   {
        //     name: "@_mrs_leela",
        //     socialHandleLink: "https://www.instagram.com/_mrs_leela/",
        //     ex: true,
        //   },
        // ]}
        techniques={[
          "Compliments and love-bombing of victims.",
          "Long cons after building up trust over time.",
          "Sending fake balance and transfer bank statements.",
          "Meditating with his victims, and having shared experiences to build trust.",
          "Identifying a need that the victim desires and faking the ability to provide it.",
          "Use of a fictious story about his desire to build a healing foundation.",
        ]}
        documents={[
          require("./assets/pdfs/mario/Mario.pdf"),
          require("./assets/pdfs/mario/DBFraud-Notice.pdf"),
          require("./assets/pdfs/mario/GeoCoreFakeContract.pdf"),
          require("./assets/pdfs/mario/ANDRASTER-CIS.pdf"),
          require("./assets/pdfs/mario/fakeBalance.pdf"),
        ]}
        images={[
          require("./assets/mario1.jpeg"),
          require("./assets/mario3.png"),
          require("./assets/mario4.png"),
        ]}
        voiceSamples={[
          require("./assets/audio/mario1.ogg"),
          require("./assets/audio/mario2.ogg"),
        ]}
        educationalMedia={[
          {
            displayName: "What is a psychopath?",
            link: "https://www.verywellmind.com/what-is-a-psychopath-5025217",
          },
          {
            displayName: "Narcissist, Psychopath, or Sociopath?",
            link: "https://www.youtube.com/watch?v=6dv8zJiggBs&ab_channel=MedCircle",
          },
          {
            displayName: "7 Characteristics of the Modern Psychopath",
            link: "https://www.psychologytoday.com/us/blog/communication-success/201810/7-characteristics-of-the-modern-psychopath",
          },
          {
            displayName: "Four ways to spot a con artist",
            link: "https://www.cnbc.com/2020/01/03/four-sure-fire-ways-to-spot-a-con-artistand-what-to-do-when-you-see-them.html",
          },
        ]}
      />
    </ChakraProvider>
  );
}

export default App;
